<template>
  <div class="_bg-default _80w _100vh d-flex">
    <div class="_100top _60-w pa-3" v-if="app">
      <v-btn dark depressed to="/psikolog/personal" color="green" rounded>
        <v-icon>mdi-reply</v-icon> <b>Back</b></v-btn
      >
      <h3>My Applications</h3>
      <v-card v-for="item in app.data" :key="item.id" class="pa-3 mt-2 d-flex">
        <div class="mr-3">
          <img
            :src="`${env}/vacancies/${item.logo}`"
            height="50px"
            alt="log-company"
          />
        </div>
        <div>
          <section>
            <b>{{ item.judul }}</b>
          </section>
          <section>
            <p class="ma-0">{{ item.perusahaan }}</p>
          </section>
          <section class="d-flex align-center">
            <v-icon>mdi-calendar</v-icon>
            <small
              >Sent on {{ $date(item.created_at).format("DD MMMM YYYY") }},
              {{ $date(item.created_at).format("HH.ss A") }}
            </small>
          </section>
          <section class="mt-1">
            <v-btn rounded color="orange" depressed> {{ item.status }}</v-btn>
          </section>
        </div>
      </v-card>
    </div>
    <div class="_recent _100top _10w _100vh"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      dialogFeed: false
    };
  },
  computed: {
    ...mapState({
      app: state => state.vacancies.application,
      env: state => state.API_URL
    })
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$store.dispatch("vacancies/myApps");
    }
  }
};
</script>

<style scoped>
._60-w {
  width: 60%;
}
._10w {
  width: 40%;
  padding-right: 20px;
}
@media (max-width: 576px) {
  ._60-w {
    width: 100%;
  }
  ._recent {
    display: none !important;
  }
}
@media (max-width: 768px) {
  ._60-w {
    width: 100%;
  }
  ._recent {
    display: none !important;
  }
}
</style>
